import React from 'react'
import styled from 'styled-components'
import App from 'App'
import { COLOR_CONSTANTS, colors, radius, fontSizes, lineHeights } from 'theme'
import { CONTAINER_MAX_WIDTH, SCHEDULE_DEMO_LINK } from 'consts'
import SEO from 'components/SEO'
import { Flex, Grid } from 'components/Layout'
import Container from 'components/Container'
import { Text, H1, H2, H3 } from 'components/Typography'
import Footer from 'components/Footer'
import LocalImage from 'components/LocalImage'
import G2FeaturesComponent from 'components/G2FeaturesComponent'

import CarouselReviewSection from 'routes/Home/components/CarouselReviewSection'

import imageHeader from 'static/images/solutions_agencies/header_picture.webp'
import imageOnboard from 'static/images/solutions_agencies/onboard.webp'
import imageAwesomeWork from 'static/images/solutions_agencies/awesome_work.webp'
import imageReviewPerson from 'static/images/solutions_agencies/review_image.jpeg'
import imageYellowLine from 'static/images/solutions_agencies/yellow_line.svg'
import imageScaleEfforts from 'static/images/solutions_agencies/scale_efforts.webp'

import onboardImageConnect from 'static/images/solutions_agencies/onboard/connect.svg'
import onboardImageCalendar from 'static/images/solutions_agencies/onboard/calendar.svg'
import onboardImageAssign from 'static/images/solutions_agencies/onboard/assign.svg'
import onboardImageEnable from 'static/images/solutions_agencies/onboard/enable.svg'

import awesomeImageTime from 'static/images/solutions_agencies/awesome_work/time.svg'
import awesomeImageStayAhead from 'static/images/solutions_agencies/awesome_work/stay_ahead.svg'
import awesomeImageCollaborate from 'static/images/solutions_agencies/awesome_work/collaborate.svg'

import organizedAndSecureImageData from 'static/images/solutions_agencies/organized_and_secure/data.svg'
import organizedAndSecureImageControls from 'static/images/solutions_agencies/organized_and_secure/controls.svg'
import organizedAndSecureImageMistake from 'static/images/solutions_agencies/organized_and_secure/mistake.svg'

import featureImageGroup from 'static/images/solutions_agencies/features/group.svg'
import featureImageCalendar from 'static/images/solutions_agencies/features/calendar.svg'
import featureImageWorkflow from 'static/images/solutions_agencies/features/workflow.svg'
import featureImageTime from 'static/images/solutions_agencies/features/time.svg'
import featureImageReports from 'static/images/solutions_agencies/features/reports.svg'
import featureImageInbox from 'static/images/solutions_agencies/features/inbox.svg'
import featureImageReview from 'static/images/solutions_agencies/features/review.svg'
import featureImageCustomizableReports from 'static/images/solutions_agencies/features/customizable_reports.svg'

import Button from 'components/Button'
import { pxToRem } from 'helpers'
import SocialsIntegrations from '../components/SocialsIntegrations'
import GetStartedComponent2 from '../components/GetStartedComponent2'

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  background: ${({ bg }) => bg || COLOR_CONSTANTS.WHITE};
`

const StyledImageReviewPerson = styled(LocalImage)`
  border-radius: ${radius.xxl};
`

const TrialButton = styled(Button.Gradient)`
  font-size: ${fontSizes.m};
  height: 100%;
  padding-left: ${pxToRem(24)};
  padding-right: ${pxToRem(24)};
  box-shadow: 0px 18px 24px -8px rgba(36, 130, 253, 0.32);
  margin-right: 10px;
`

const StyledScheduleDemoButton = styled(Button.Reversed)`
  font-size: ${fontSizes.m};
  height: 100%;
  padding-left: ${pxToRem(24)};
  padding-right: ${pxToRem(24)};
  box-shadow: 0px 18px 24px -8px rgba(36, 130, 253, 0.32);
`

const ONBOARD_ITEMS = [
  {
    picture_url: onboardImageConnect,
    title: 'Connect your client profiles in minutes',
    description: 'Get your client’s profiles connected or give your client the ability to do that.',
  },
  {
    picture_url: onboardImageCalendar,
    title: 'Load up their content calendar',
    description: 'Schedule a ton of content for them quickly for an immediate impact.',
  },
  {
    picture_url: onboardImageAssign,
    title: 'Assign team permissions',
    description: 'Quickly assign appropriate team members access and permissions.',
  },
  {
    picture_url: onboardImageEnable,
    title: 'Enable client access to their account',
    description: 'Should your client be interested, give them access as well! They will only see their own data!',
  },
]

const AWESOME_ITEMS = [
  {
    picture_url: awesomeImageTime,
    title: 'Save time',
    description: 'Scale your efforts by utilizing powerful features to manage your content and engagement strategy.',
  },
  {
    picture_url: awesomeImageStayAhead,
    title: 'Stay ahead',
    description:
      'Plan your strategy, schedule content, find approved posts faster and track performance across networks to continually evolve.',
  },
  {
    picture_url: awesomeImageCollaborate,
    title: 'Collaborate',
    description:
      'Effectively manage work by bringing all of your team into the system for efficient and secure collaboration and communication.',
  },
]

const ORGANIZED_AND_SECURE_ITEMS = [
  {
    picture_url: organizedAndSecureImageData,
    title: 'Keep client data separated',
    description:
      'Get all your clients organized in their individual dashboards so your team can work without fear of mistakes.',
  },
  {
    picture_url: organizedAndSecureImageControls,
    title: 'Easily control access to client data',
    description:
      'Have the piece of mind knowing that client data and work can only be accessed by authorized personnel.',
  },
  {
    picture_url: organizedAndSecureImageMistake,
    title: 'Avoid costly mistakes',
    description: 'Approval workflows ensure that the right people are signing off on content before it goes live.',
  },
]

const SCALE_EFFORTS_ITEMS = [
  {
    title: 'Plan content in advance',
    description: 'Plan individual posts or upload in bulk to speed up content scheduling and planning.',
  },
  {
    title: 'Use AI for content and engagement',
    description: 'Use built-in ChatGPT module to quickly create amazing posts that perform.',
  },
  {
    title: 'Automate and customize reports',
    description: 'Quickly and easily set up awesome reports for your clients and automate their delivery.',
  },
  {
    title: 'Delegate work with team management',
    description: 'Utilize a built in permission system for detailed control of your team efforts.',
  },
]

const FEATURE_ITEMS = [
  {
    picture_url: featureImageGroup,
    title: 'Custom groups and permissions',
    description:
      'Organize your profiles into client-specific groups to separately manage permissions, reporting and social networks.',
  },
  {
    picture_url: featureImageCalendar,
    title: 'Shared content calendar',
    description:
      'Manage individual posts and campaigns in a collaborative content calendar to improve visibility and facilitate long-term planning.',
  },
  {
    picture_url: featureImageWorkflow,
    title: 'Content approval workflow',
    description:
      'Generate workflows to guide the submission, review and approval of messages to maintain oversight, safeguard brand standards and simplify collaboration.',
  },
  {
    picture_url: featureImageTime,
    title: 'Optimal send times',
    description:
      'Utilize optimization algorithms to automatically schedule content to post at the times proven to generate engagement from your social audiences.',
  },
  {
    picture_url: featureImageReports,
    title: 'Competitor reports',
    description:
      'Track competitor performance across social to benchmark against your own and to identify new opportunities in your industry.',
  },
  {
    picture_url: featureImageInbox,
    title: 'Smart Inbox',
    description:
      'Unify all connected networks and profiles into a single stream to monitor incoming messages and provide quick responses.',
  },
  {
    picture_url: featureImageReview,
    title: 'Review management',
    description: 'Manage reviews across the sites that matter to your business to inform strategy.',
  },
  {
    picture_url: featureImageCustomizableReports,
    title: 'Customizable reports',
    description:
      'Build, share and schedule unlimited custom reports that will impress, not confuse, your stakeholders.',
  },
]

const SolutionsAgencies = () => {
  return (
    <App fullHeader>
      <SEO
        title="Social Media Management tools for Agencies | Vista Social"
        description="Vista Social’s all-in-one social management solution powers agencies to lead with strategy, deliver results, deepen client relationships and level-up their offerings."
        path="/agencies/"
      />

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex flexDirection="column" alignItems="center">
            <Grid
              mt="xl"
              gridTemplateColumns={{ mobile: '1fr', desktop: '1fr 1fr' }}
              gridGap="l"
              width="100%"
              alignItems="center"
              position="relative"
            >
              <Flex height="100%" width="100%" justifyContent="center" alignItems="center">
                <Flex flexDirection="column" maxWidth={{ mobile: '100%', desktop: '495px' }}>
                  <H1
                    fontSize="4xl"
                    fontWeight="bold"
                    color={COLOR_CONSTANTS.DENIM}
                    textAlign={{ mobile: 'center', desktop: 'left' }}
                  >
                    Social Media Management Tools For{' '}
                    <H1
                      as="span"
                      fontSize="4xl"
                      fontWeight="bold"
                      color="primary"
                      textAlign={{ mobile: 'center', desktop: 'left' }}
                    >
                      Marketing Agencies 💫
                    </H1>
                  </H1>
                  <Flex mt="m" pt="s">
                    <H2
                      color="secondaryText"
                      fontSize="m"
                      textAlign={{ mobile: 'center', desktop: 'left' }}
                      fontWeight="normal"
                      lineHeight={lineHeights.xxl}
                    >
                      Vista Social’s all-in-one social management solution empowers agencies, social media managers and
                      freelancers to lead with innovative strategy, deliver stunning results, foster client
                      relationships and scale offerings.
                    </H2>
                  </Flex>

                  <Flex mt="l" pt="m">
                    <Flex
                      height={{ mobile: pxToRem(56), tablet: pxToRem(52) }}
                      width="100%"
                      justifyContent={{ mobile: 'center', desktop: 'flex-start' }}
                    >
                      <TrialButton as="a" href="/pricing" target="_blank" rel="noreferrer noopener nofollow">
                        Start your free trial
                      </TrialButton>

                      <StyledScheduleDemoButton
                        as="a"
                        href={SCHEDULE_DEMO_LINK}
                        target="_blank"
                        rel="noreferrer noopener nofollow"
                      >
                        Request a demo
                      </StyledScheduleDemoButton>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
              <Flex width="100%" ml="auto" mr={{ mobile: 'auto', desktop: 0 }}>
                <LocalImage
                  src={imageHeader}
                  width="100%"
                  mx="auto"
                  maxWidth="467px"
                  alt="Effectively Manage Multi-Location Brands"
                />
              </Flex>
            </Grid>

            <Flex
              mt={{ mobile: 'l', tablet: 'm' }}
              pt="m"
              flexDirection="column"
              alignItems="center"
              mb={{ mobile: 'l', desktop: 'l' }}
            >
              <G2FeaturesComponent />
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper bg={COLOR_CONSTANTS.SALT}>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          width="100%"
          height="100%"
        >
          <Flex
            justifyContent={{ mobile: 'center', desktop: 'space-between' }}
            flexDirection={{ mobile: 'column', desktop: 'row' }}
            alignItems="center"
          >
            <Flex mt={{ mobile: 'l', desktop: '0' }} order={{ mobile: 2, desktop: 2 }} maxWidth="647px" width="100%">
              <LocalImage src={imageAwesomeWork} width="100%" alt="Do awesome work" />
            </Flex>
            <Flex
              order={{ mobile: 1, desktop: 1 }}
              flexDirection="column"
              alignItems={{ mobile: 'center', desktop: 'flex-start' }}
              maxWidth="540px"
            >
              <Text
                fontWeight="bold"
                fontSize="3xl"
                color={COLOR_CONSTANTS.DENIM}
                textAlign={{ mobile: 'center', desktop: 'left' }}
              >
                Do{' '}
                <Text as="span" fontWeight="bold" fontSize="3xl" color="primary">
                  awesome
                </Text>{' '}
                work
              </Text>

              <Text mt="m" color="secondaryText" fontSize="l" textAlign={{ mobile: 'center', desktop: 'left' }}>
                Getting clients in the door is one thing, but your ability to execute is what builds trust. Vista Social
                makes it easy to deliver results for your clients by improving your day-to-day workflow with
                best-in-class tools.
              </Text>
              <Grid mt="l" pt="m" gridGap="l" gridTemplateColumns="repeat(1, 1fr)">
                {AWESOME_ITEMS.map(({ picture_url, title, description }) => (
                  <Flex flexDirection="column" key={title} width="100%" maxWidth={{ mobile: '100%', desktop: '430px' }}>
                    <Flex alignItems="center">
                      <LocalImage src={picture_url} width="44px" height="44px" alt={title} />
                      <Text ml="m" color={COLOR_CONSTANTS.DENIM} fontWeight="bold" fontSize="l">
                        {title}
                      </Text>
                    </Flex>
                    <Text mt="m" fontSize="m" color="secondaryText" textAlign="left">
                      {description}
                    </Text>
                  </Flex>
                ))}
              </Grid>
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth="963px"
          width="100%"
          height="100%"
        >
          <Flex maxWidth="680px" flexDirection="column" mx="auto">
            <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
              Onboard new clients with{' '}
              <H1 as="span" fontSize="3xl" fontWeight="bold" color="primary">
                ease
              </H1>
            </H3>
          </Flex>
          <Flex
            justifyContent={{ mobile: 'center', desktop: 'space-between' }}
            flexDirection={{ mobile: 'column', desktop: 'row' }}
            alignItems="center"
            mt="l"
            pt="m"
          >
            <Flex mt={{ mobile: 'l', desktop: '0' }} order={{ mobile: 2, desktop: 1 }} maxWidth="452px" width="100%">
              <LocalImage src={imageOnboard} width="100%" alt="Onboard new clients with ease" />
            </Flex>
            <Flex
              order={{ mobile: 1, desktop: 2 }}
              flexDirection="column"
              alignItems={{ mobile: 'center', desktop: 'flex-start' }}
              maxWidth="413px"
            >
              <Grid mt="l" pt="m" gridGap="l" gridTemplateColumns="repeat(1, 1fr)">
                {ONBOARD_ITEMS.map(({ picture_url, title, description }) => (
                  <Flex flexDirection="column" key={title} width="100%">
                    <Flex alignItems="center">
                      <LocalImage src={picture_url} width="44px" height="44px" alt={title} />
                      <Text ml="m" color={COLOR_CONSTANTS.DENIM} fontWeight="bold" fontSize="l" textAlign="left">
                        {title}
                      </Text>
                    </Flex>
                    <Text mt="m" fontSize="m" color="secondaryText" textAlign="left">
                      {description}
                    </Text>
                  </Flex>
                ))}
              </Grid>
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper bg="radial-gradient(51.88% 56.86% at 31.54% 45.31%, #0063E3 36.77%, #0250C9 100%)">
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth="1108px"
          width="100%"
          height="100%"
        >
          <Flex
            justifyContent={{ mobile: 'center', desktop: 'space-between' }}
            flexDirection={{ mobile: 'column', desktop: 'row' }}
            alignItems="center"
          >
            <Flex
              display={{ mobile: 'none', desktop: 'flex' }}
              mt={{ mobile: 'l', desktop: '0' }}
              order={{ mobile: 2, desktop: 1 }}
              maxWidth="325px"
              width="100%"
            >
              <StyledImageReviewPerson src={imageReviewPerson} width="100%" alt="Riley Welch" />
            </Flex>
            <Flex
              order={{ mobile: 1, desktop: 2 }}
              flexDirection="column"
              alignItems={{ mobile: 'center', desktop: 'flex-start' }}
              maxWidth="600px"
            >
              <Text textAlign="left" fontWeight="medium" fontSize="xxl" color="white" fontStyle="italic">
                «Absolute game changer! If you are looking to manage all your clients social media accounts, in one
                location, with ease, then look no further than Vista Social. It has made my overall job as a social
                media marketer so much easier and efficient. I can't recommend Vista Social enough to anyone working in
                this industry.»
              </Text>
              <Flex mt="l" width="100%" alignItems="center">
                <Flex display={{ mobile: 'flex', desktop: 'none' }} mr="m">
                  <StyledImageReviewPerson src={imageReviewPerson} width="48px" height="48px" alt="Riley Welch" />
                </Flex>
                <Flex flexDirection="column" width="100%">
                  <Text color={COLOR_CONSTANTS.SALT} fontWeight="bold" fontSize="xl" textAlign="left">
                    Riley Welch
                  </Text>
                  <Text color={COLOR_CONSTANTS.SALT} fontSize="s" textAlign="left">
                    Owner at 92 Social
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex mt="m" flexDirection="column" justifyContent="center" alignItems="center">
            <Flex maxWidth="540px" width="100%" height="1px" bg="#B6CEF0" opacity={0.5} />
            <Flex mt="l" pt="m">
              <Flex mt="s" flexDirection="column">
                <Text as="a" href="/pricing" fontWeight="bold" color="white" fontStyle="xl">
                  Not sure? Let’s try Vista Social for free 💛
                </Text>
                <Flex mt="xs" justifyContent={{ mobile: 'center', desktop: 'flex-end' }}>
                  <LocalImage
                    src={imageYellowLine}
                    width={{ mobile: '260px', desktop: '222px' }}
                    alt="Try Vista Social for free"
                  />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex maxWidth="750px" flexDirection="column" mx="auto">
            <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
              <H1 as="span" fontSize="3xl" fontWeight="bold" color="primary">
                Stay{' '}
              </H1>
              organized and secure
            </H3>
          </Flex>

          <Grid
            width="100%"
            mt="l"
            pt="m"
            gridGap="l"
            gridTemplateColumns={{ mobile: 'repeat(1, 1fr)', tablet: 'repeat(3, 1fr)' }}
          >
            {ORGANIZED_AND_SECURE_ITEMS.map(({ picture_url, title, description }) => (
              <Flex p="m" flexDirection="column" key={title} width="100%" borderRadius={radius.l} bg={colors.primary}>
                <Flex flexDirection="column" p="s">
                  <LocalImage src={picture_url} width="48px" height="48px" alt={title} />
                  <Flex mt="m" minHeight={{ mobile: 'auto', tablet: '60px' }}>
                    <Text color="white" fontWeight="bold" fontSize="xl" textAlign="left">
                      {title}
                    </Text>
                  </Flex>

                  <Text mt="s" fontSize="m" color={COLOR_CONSTANTS.SALT} textAlign="left">
                    {description}
                  </Text>
                </Flex>
              </Flex>
            ))}
          </Grid>

          <Flex mt="m" pt="s" flexDirection="column" width="100%">
            <SocialsIntegrations />
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper bg={COLOR_CONSTANTS.SALT}>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex
            justifyContent={{ mobile: 'center', desktop: 'space-between' }}
            flexDirection={{ mobile: 'column', desktop: 'row' }}
            alignItems="center"
          >
            <Flex mt={{ mobile: 'l', desktop: '0' }} order={{ mobile: 2, desktop: 2 }} maxWidth="506px" width="100%">
              <LocalImage src={imageScaleEfforts} width="100%" alt="Scale your efforts" />
            </Flex>
            <Flex
              order={{ mobile: 1, desktop: 1 }}
              flexDirection="column"
              alignItems={{ mobile: 'center', desktop: 'flex-start' }}
              maxWidth={{ mobile: '100%', desktop: '540px' }}
            >
              <Text
                fontWeight="bold"
                fontSize="3xl"
                color={COLOR_CONSTANTS.DENIM}
                textAlign={{ mobile: 'center', desktop: 'left' }}
              >
                <Text as="span" fontWeight="bold" fontSize="3xl" color="primary">
                  Scale
                </Text>{' '}
                your efforts
              </Text>
              <Text mt="m" pt="s" color="secondaryText" fontSize="l" textAlign={{ mobile: 'center', desktop: 'left' }}>
                Great ideas get new clients in the door, but your ability to execute is what builds trust. Vista Social
                makes it easy to deliver on your promises by making your day-to-day work more efficient.
              </Text>
              <Grid mt="l" pt="m" gridGap="40px" gridTemplateColumns="repeat(1, 1fr)">
                {SCALE_EFFORTS_ITEMS.map(({ title, description }) => (
                  <Flex key={title}>
                    <Flex
                      width="8px"
                      bg={colors.primary}
                      minWidth="8px"
                      borderRadius={`0px ${radius.l} ${radius.l} 0px`}
                    />
                    <Flex ml="m" flexDirection="column">
                      <Text fontWeight="bold" fontSize="l" textAlign="left" color={COLOR_CONSTANTS.DENIM}>
                        {title}
                      </Text>
                      <Text mt="s" fontSize="m" color="secondaryText" textAlign="left">
                        {description}
                      </Text>
                    </Flex>
                  </Flex>
                ))}
              </Grid>
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
            What’s included:{' '}
            <H1 as="span" fontSize="3xl" fontWeight="bold" color="primary">
              Core Features
            </H1>
          </H3>

          <Grid
            mt="xl"
            gridGap="m"
            gridTemplateColumns={{
              mobile: 'repeat(1, 1fr)',
              mobileLarge: 'repeat(2, 1fr)',
              desktop: 'repeat(4, 1fr)',
            }}
          >
            {FEATURE_ITEMS.map(({ title, description, picture_url }) => {
              return (
                <Flex
                  flexDirection="column"
                  key={title}
                  width="100%"
                  bg={COLOR_CONSTANTS.WHITE}
                  borderRadius={radius.l}
                  py="s"
                  px="s"
                >
                  <Flex alignItems="center">
                    <LocalImage src={picture_url} width="44px" height="44px" alt={title} />
                    <Text ml="s" color={COLOR_CONSTANTS.DENIM} fontWeight="bold" fontSize="l" textAlign="left">
                      {title}
                    </Text>
                  </Flex>
                  <Text mt="m" fontSize="m" color="secondaryText" textAlign="left">
                    {description}
                  </Text>
                </Flex>
              )
            })}
          </Grid>
        </Container>
      </Wrapper>

      <CarouselReviewSection />

      <GetStartedComponent2 />

      <Footer />
    </App>
  )
}

export default SolutionsAgencies
